/**
 * Проверка, является ли пользователь администратором
 * @param {number} id - идентификатор пользователя в таблице t_users (users_id), 
 *                      который проверяется на наличие прав администратора
 * @param {Array<number>} adminIds - массив идентификаторов пользователей, которые имеют права администратора, 
 *                                   в модуле, который использует функцию
 * @returns {boolean} - true, если пользователь является администратором, иначе false
 */

const userHaveAdmins: funcProps = (id, adminIds = []) => {
  if (!id) return false;
  
  // Для тестирования функции используется user_id = 6 (kabieff@yandex.ru)
  const userAdminTest = [6].find(el => el === id);
  
  // Пользователи, которые имеют рассширенные права во всей crm:
  //  15 (olga@grasias.ru), 56 (maria@grasias.ru), 10 (maria_grom@bk.ru)
  const userAdminSuper = [15, 10, 56].find(el => el === id);

  // Пользователи, которые имеют права администратора в отдельном модуле,
  // в котором используется функция
  const userAdmin = adminIds.find(el => el === id);

  if (userAdminTest || userAdminSuper || userAdmin) return true;

  return false;
};

export default userHaveAdmins;

type funcProps= (id?: number, adminIds?: Array<number>) => boolean;
