import { FC } from 'react';
import { useSession } from 'next-auth/react';
import { Card, DatePicker, Input, Form, message } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash';

import { InformationProps } from './Information.props';
import { useUpdateOrderByPkMutation } from '~/apollo/generated/hooks';
import { userHaveAdmins } from '~/utils';

const Information: FC<InformationProps> = ({
  order,
  title = 'Информация',
  headStyle = { padding: 0, borderBottomWidth: 0 },
  bordered = false,
  bodyStyle = { padding: '6px 0' },
  ...props
}) => {
  const [form] = Form.useForm();

  const { data: session } = useSession();
  const userHaveAdmin = userHaveAdmins(session?.user.user_id);

  const [updateInfo] = useUpdateOrderByPkMutation({
    onError: (error) => {
      message.error(`
        При обновлении заказа произошла ошибка. Сообщение ошибки: ${error.message}
      `);
    },
    refetchQueries: ['getCurrentOrder']
  });

  const onDateChange = () => {
    // если статус сделки не "Закрыта" и не "Отгружен", то можно менять квартал
    if(order?.status?.id !== 4 && order?.status?.id !== 3) return true;

    // проверим, есть ли права на изменение квартала, если нет, сбросим значение
    if (!userHaveAdmin) {
      message.error('Изменение квартала закрытой сделки доступно только администраторам');
      // необходимо сбросить значение в поле date
      form.setFieldsValue({ date:  order.order_quarter?moment(order.order_quarter, 'YYYY-MM-DD'):null });
    }
  };

  const onValuesChange = () => {
    // description обновляем всегда
    // order_quarter только если userHaveAdmin === true или статус сделки не "Закрыта" и не "Отгружен"
    form
      .validateFields()
      .then((values) => {
          const newQuarter = values.date?.format('YYYY-MM-DD') || null;
          const allowUpdateQuarter = userHaveAdmin || (order?.status?.id !== 4 && order?.status?.id !== 3);
          updateInfo({
          variables: {
            set: {
              description: values.description,
              order_quarter: allowUpdateQuarter ? newQuarter : order.order_quarter,
            },
            orderId: order.id
          }
        });
      })
      .catch(() => ({}));
  };

  return (
    <Card
      title={title}
      headStyle={headStyle}
      bodyStyle={bodyStyle}
      bordered={bordered}
      {...props}
    >
      <Form
        form={form}
        autoComplete="off"
        onValuesChange={debounce(onValuesChange, 300)}
        initialValues={{
          description: order.description?.toString(),
          date: order.order_quarter ? moment(order.order_quarter, 'YYYY-MM-DD') : undefined,
        }}
      >
        <Form.Item
          name="description"
          style={{ marginBottom: 8 }}
        >
          <Input.TextArea rows={3} disabled={!!order.archived} />
        </Form.Item>
        <Form.Item name="date" noStyle={true}>
          <DatePicker 
            picker="month" 
            format="MMMM YYYY" 
            disabled={!!order.archived} 
            onChange={onDateChange}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Information;
